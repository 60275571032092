export const SOCKET_PLAYER_LIST = "SOCKET_PLAYER_LIST";
export const SOCKET_PLAYER_READY = "SOCKET_PLAYER_READY";
export const SOCKET_PLAYER_NOT_READY = "SOCKET_PLAYER_NOT_READY";

export const SOCKET_GAME_START = "SOCKET_GAME_START";
export const SOCKET_CURRENT_QUESTION = "SOCKET_CURRENT_QUESTION";

export const SOCKET_PLAYER_ANSWERED = "SOCKET_PLAYER_ANSWERED";
export const SOCKET_PLAYER_ANSWERED_OPEN = "SOCKET_PLAYER_ANSWERED_OPEN";
export const SOCKET_OPEN_TO_RECIPIENT = "SOCKET_OPEN_TO_RECIPIENT";

export const SOCKET_QUESTION_COMPLETE = "SOCKET_QUESTION_COMPLETE";
export const SOCKET_QUESTION_RESULTS = "SOCKET_QUESTION_RESULTS";
export const SOCKET_NEXT_QUESTION = "SOCKET_NEXT_QUESTION";
export const SOCKET_GAME_OVER = "SOCKET_GAME_OVER";

export const SOCKET_DISCONNECTED = "SOCKET_DISCONNECTED";
export const SOCKET_RECONNECTED = "SOCKET_RECONNECTED";
export const HYDRATE_ROOM = "HYDRATE_ROOM";
export const HYDRATE_ANSWERED_QUESTIONS = "HYDRATE_ANSWERED_QUESTIONS";
export const HYDRATE_ANSWERED_PLAYERS = "HYDRATE_ANSWERED_PLAYERS";

export const VIEWER_READY = "VIEWER_READY";
export const VIEWER_ANSWER = "VIEWER_ANSWER";

export const HOST_SETTING = "HOST_SETTING";
export const HOST_TRANSFER = "HOST_TRANSFER";
export const HOST_KICK_PLAYER = "HOST_KICK_PLAYER";

export const SOCKET_NEW_HOST = "SOCKET_NEW_HOST";
export const SOCKET_GAME_SETTINGS = "SOCKET_GAME_SETTINGS";
